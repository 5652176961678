import {
  Button,
  // Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  amountEntryType,
  ListReconSummaryAccountingSettingResponse,
} from "../../../entity/recon-entity/ReconInterfaces";
import Loader from "../../Common/Loader";
// import ReconSettingsTable from "../../ReactTable/ReconSettingsTable";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import CustomGapDescription from "./CustomGapDescription";

const ReconSummaryCalculation = () => {
  const [loader, setLoader] = useState(true);
  const [outOfPeriod, setOutOfPeriod] = useState<boolean>(false);
  const [accountingTerminology, setAccountingTerminology] = useState<string>(amountEntryType.positiveNegative);
  const [numberOfDays, setNumberOfDays] = useState<number>(null);
  const [loadingAmountBtn, setLoadingAmountBtn] = useState(false);

  useEffect(() => {
    listReconSummaryAccountingSettingsForUI();
  }, []);

  const listReconSummaryAccountingSettingsForUI = () => {
    useFetch<ListReconSummaryAccountingSettingResponse>(
      API_ENDPOINTS.LIST_RECON_SUMMARY_ACCOUNTING_SETTINGS_FOR_UI.url,
      "GET",
      {
        failureMessage: API_ENDPOINTS.LIST_RECON_SUMMARY_ACCOUNTING_SETTINGS_FOR_UI.failureMessage,
        thenCallBack: (response) => {
          setOutOfPeriod(response.data.isOutOfPeriodSelected);
          setAccountingTerminology(response.data.amountEntryType);
          setNumberOfDays(response.data.dateRangeForOutOfPeriod);
          setLoader(false);
        },
      }
    );
  };

  const UpdateAmountEntryType = () => {
    setLoadingAmountBtn(true);
    if ((numberOfDays !== 0 && numberOfDays !== null) || !outOfPeriod) {
      useFetch(API_ENDPOINTS.UPDATE_AMOUNT_ENTRY_TYPE.url, "POST", {
        failureMessage: API_ENDPOINTS.UPDATE_AMOUNT_ENTRY_TYPE.failureMessage,
        showSuccessToast: true,
        data: {
          amountEntryType: accountingTerminology,
        },
        thenCallBack: () => {
          setLoadingAmountBtn(false);
        },
        catchCallBack: () => {
          setLoadingAmountBtn(false);
        },
      });
    }
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <Grid container={true}>
            <Grid item={true} xs={12} sm={12} md={12}>
              <Grid className="mb_15">
                <Paper sx={{ p: 2 }}>
                  <div className="d_flex">
                    <div style={{ width: "90%" }}>
                      <div className="vertical_center_align">
                        <div className="fs_20 fw_600">Amount Entry terminology to be used for accounting</div>
                        <div className="ml_60">
                          <FormControl>
                            <RadioGroup
                              row={true}
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={accountingTerminology}
                              onChange={(e) => {
                                if (e.target.value === amountEntryType.debitCredit) {
                                  setAccountingTerminology(amountEntryType.debitCredit);
                                } else {
                                  setAccountingTerminology(amountEntryType.positiveNegative);
                                }
                              }}
                            >
                              <FormControlLabel
                                value={amountEntryType.debitCredit}
                                control={<Radio />}
                                label="Debit/Credit"
                              />
                              <FormControlLabel
                                value={amountEntryType.positiveNegative}
                                control={<Radio />}
                                label="+ve/-ve"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                    <div className="vertical_center_align">
                      <Button
                        disabled={loadingAmountBtn}
                        variant="contained"
                        onClick={() => {
                          UpdateAmountEntryType();
                        }}
                        className="purple_btn"
                      >
                        <LoadingIcon loading={loadingAmountBtn} />
                        Save
                      </Button>
                    </div>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Grid>

          <Grid className="mt_25">
            <CustomGapDescription />
          </Grid>
        </>
      )}
    </>
  );
};

export default ReconSummaryCalculation;
