import { ChevronLeft, Settings } from "@mui/icons-material";
import { Box, Button, FormControl, FormLabel, IconButton, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import { ListUserEmailSettingRes, UseCaseType } from "src/entity/recon-entity/ReconInterfaces";
import { exists } from "src/Utils/Common/Validators";
import { uiLoggerNameDisputeResolution } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { UpdateEmailTemplateDialog } from "../../PartnerCommunication/Common/CommonDialogs";
import EmailEditorWithTemplate from "../../PartnerCommunication/Common/EmailEditorWithTemplate";
import { EmailFields } from "../../PartnerCommunication/Common/ViewReplyMailThread";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID } from "../../PartnerCommunication/CommonLegacy/CommonComponents";
import { PartnerCommsContext } from "../../PartnerCommunication/Context/PartnerCommunicationContext";
import { NdAsyncButton } from "../../PartnerCommunication/MsmePartnerPortal/CommonComponents";
import { EmailInput } from "../../ReconMIS/AutoCompletes";
import { ClosureTrackerContext } from "../ClosureTracker/ClosureTracker";
import { DisputeResolutionEmailStatuses } from "../DRInterfaces";
import { ViewEmailThreadRes } from "./ViewReplyMailThread";

interface SingleMailProps {
  handleBack: () => void;
}

const SingleMailing: React.FC<SingleMailProps> = ({ handleBack }) => {
  const { actor } = useContext(userContext);
  const { currentRow, businessPartnerId, branchCode, companyId, listUserEmailTemplates, listAllBusinessPartnersWSR } =
    useContext(ClosureTrackerContext);
  const { emailTemplates, emailTemplateContent } = useContext(PartnerCommsContext);

  const [userEmailSetting, setUserEmailSetting] = useState<ListUserEmailSettingRes["userEmailSetting"]>({} as any);

  const [emailSubject, setEmailSubject] = useState(emailTemplateContent?.emailSubject || "");
  const [editorContent, setEditorContent] = useState(emailTemplateContent?.emailBody || "");
  const [emailTemplateId, setEmailTemplateId] = useState(emailTemplateContent?.templateId || null);
  const [templateError, setTemplateError] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [toEmails, setToEmails] = useState<string[]>([]);
  const [ccEmails, setCcEmails] = useState<string[]>([]);

  const emailTemplateName = emailTemplates.find((item) => item.templateId === emailTemplateId)?.templateName;

  // update email Template section
  const [openUpdateEmailTemplate, setOpenUpdateEmailTemplate] = useState(false);

  const ViewEmailThread = () => {
    return useFetch<ViewEmailThreadRes>("/api/DisputeResolutionEmail/ViewEmailThread", "GET", {
      config: {
        params: {
          companyId: companyId,
          branchCode: branchCode,
          businessPartnerId: businessPartnerId,
          newMailInitiationCount: null, // TODO: Get New Mail Initiation Count
          ticketId: currentRow?.ticketId,
        },
      },
      thenCallBack: (res) => {
        if (res.data) {
          setToEmails(res.data.toEmails || []);
          setCcEmails(res.data.ccEmails || []);
        }
      },
    });
  };

  // User Display Name and Email Id
  const getListUserEmailSetting = async () => {
    return useFetch<ListUserEmailSettingRes>("/api/partnerCommunication/ListUserEmailSetting", "GET", {
      config: {
        params: {
          userId: actor.userId,
        },
      },
      thenCallBack: (response) => {
        if (exists(response.data.userEmailSetting)) {
          setUserEmailSetting(response.data.userEmailSetting);
        }
      },
    });
  };

  useEffect(() => {
    getListUserEmailSetting();
    ViewEmailThread();
  }, []);

  const handleClose = () => {
    handleBack();
  };

  const checkIfUserEmailTemplateHasToUpdate = async () => {
    if (emailSubject.trim() === "") {
      setTemplateError(true);
      return;
    }

    const bodyFormData = new FormData();
    bodyFormData.append("emailBody", editorContent);
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("templateType", UseCaseType.disputeResolution);
    bodyFormData.append("templateName", emailTemplateName);

    return new Promise<void>((resolve, reject) => {
      useFetch("/api/partnerCommunication/CheckIfEmailTemplateNeedsToBeAdded", "POST", {
        data: bodyFormData,
        config: {
          headers: { "Content-Type": "multipart/form-data" },
        },
        thenCallBack: async (response) => {
          // check response here
          if (response.data.isTemplateUpdated) {
            setOpenUpdateEmailTemplate(true);
            resolve();
            // console.log("so why not open");
          } else {
            await sendEmail();
            resolve();
          }
        },
        catchCallBack: reject,
        errorCallback: reject,
      });
    });
  };

  const updateUserEmailTemplate = async () => {
    setOpenUpdateEmailTemplate(false);

    if (emailSubject.trim() === "") {
      setTemplateError(true);
      return;
    }

    const bodyFormData = new FormData();
    bodyFormData.append("receiverId", businessPartnerId?.toString());
    bodyFormData.append("emailBody", editorContent);
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("templateName", emailTemplateName);
    bodyFormData.append("templateType", UseCaseType.disputeResolution);
    bodyFormData.append("senderCompanyId", companyId);

    if (files !== null && files.length > 0) {
      for (const item of files) {
        bodyFormData.append("file", item);
      }
    }

    return new Promise<void>((resolve, reject) => {
      useFetch("/api/partnerCommunication/AddUserEmailTemplate", "POST", {
        showSuccessToast: true,
        data: bodyFormData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
        thenCallBack: async () => {
          await sendEmail();

          // reload templates after Adding/Updating User Email Template
          listUserEmailTemplates();

          resolve();

          // props.setLoader(true);
        },
        catchCallBack: reject,
        errorCallback: reject,
      });
    });
  };

  const sendEmail = async () => {
    // const EndPointLR = "/api/ledgerRequestPortal/SendEmailForLedgerRequest";
    const EndPointBC = "/api/DisputeResolutionEmail/SendEmail";

    if (emailSubject.trim() === "") {
      setTemplateError(true);
      return;
    }

    const bodyFormData = new FormData();
    bodyFormData.append("businessPartnerId", businessPartnerId?.toString());
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("emailBody", editorContent);
    bodyFormData.append("companyId", companyId || DEFAULT_COMPANY_ID);
    bodyFormData.append("branchCode", branchCode || DEFAULT_BRANCH_CODE);
    bodyFormData.append("to", toEmails.join(","));
    bodyFormData.append("cc", ccEmails.join(","));
    // bodyFormData.append("pdfTemplateIds", pdfTemplateIds.join(","));
    bodyFormData.append("ticketId", currentRow?.ticketId?.toString());

    if (files !== null && files.length > 0) {
      for (const item of files) {
        bodyFormData.append("file", item);
      }
    }

    return await useFetch(EndPointBC, "POST", {
      showSuccessToast: true,
      data: bodyFormData,
      thenCallBack: (_res) => {
        // list new ROWs in Main Table;
        listAllBusinessPartnersWSR();

        // go back to main page
        handleClose();
      },
      catchCallBack: (_err) => {
        // setShowLoadingIcon(false);
      },
    });
  };

  return (
    <>
      <Stack gap={2} mb={"90px"}>
        {/* Top Bar */}

        {/* Main Setup Section */}
        <Box component="section" className="emailSetupSection" sx={{ bgcolor: "#fff" }}>
          {/* Setup Email Header Bar */}
          <Box
            className="header_bar space_between"
            alignItems="center"
            p={2}
            sx={{ bgcolor: "#fff", borderBottom: 1, borderColor: "#dedede" }}
          >
            <Typography variant="h6" fontWeight="600">
              Setup Email
            </Typography>
            <IconButton size="small" disabled>
              <Settings />
            </IconButton>
          </Box>

          <Box
            sx={{
              p: 2,
              bgcolor: "#f8f8f8",
              borderBottom: 1,
              borderColor: "#dedede",
            }}
          >
            <Stack spacing={2}>
              <EmailFields label="From:" emails={[userEmailSetting?.from_email]} isSender />

              <FormControl>
                <Box display={"inline-flex"} gap={4} alignItems="center">
                  <FormLabel className="" sx={{ width: 40 }}>
                    To :
                  </FormLabel>
                  <EmailInput emails={toEmails} setEmails={setToEmails} label="" limitTags={3} sx={{ width: "100%" }} />
                </Box>
              </FormControl>

              <FormControl>
                <Box display={"inline-flex"} gap={4} alignItems="center">
                  <FormLabel className="" sx={{ width: 40 }}>
                    CC :
                  </FormLabel>
                  <EmailInput emails={ccEmails} setEmails={setCcEmails} label="" limitTags={3} sx={{ width: "100%" }} />
                </Box>
              </FormControl>

              {/* <EmailFields label="From:" emails={[userEmailSetting?.from_email]} isSender />
            <EmailFields label="To:" emails={emailsList.toEmails} />
            <EmailFields label="CC:" emails={emailsList.ccEmails} /> */}
            </Stack>
          </Box>

          <EmailEditorWithTemplate
            inputEditorState={{ emailTemplateId: emailTemplateId, templateError: templateError }}
            returnEditorState={(emailSubject, editorContent, templateId, files) => {
              setEmailSubject(emailSubject);
              setEditorContent(editorContent);
              setEmailTemplateId(templateId);

              setFiles(files);
            }}
            sectionsCustomizations={{
              subject: currentRow && currentRow.emailStatus === DisputeResolutionEmailStatuses.EmailNotSent,
            }}
            otherProps={{
              companyId: companyId,
              branchCode: branchCode,
              listUserEmailTemplates: listUserEmailTemplates,
              templateType: UseCaseType.disputeResolution,
            }}
          />
        </Box>
      </Stack>

      {/* Bottom Navigation Bar */}
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: -2,
          width: "100%",
          boxShadow: "0px -2px 4px 0px rgba(0, 0, 0, 0.08)",
          background: "#fff",
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            justifyContent: "space-between",
            maxWidth: "900px",
            marginX: "auto",
            padding: "24px",
          }}
        >
          <Stack sx={{ display: "flex", flexDirection: "row", gap: "16px" }}>
            <Button color="inherit" onClick={handleBack} startIcon={<ChevronLeft />}>
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />

            <NdAsyncButton
              className="theme_btn"
              buttonComponent={Button}
              onClick={async () => {
                uiLogger(
                  uiLoggerNameDisputeResolution.UI_DR_CT_SendFirstMail_Click.functionName,
                  companyId,
                  branchCode,
                  {
                    message: uiLoggerNameDisputeResolution.UI_DR_CT_SendFirstMail_Click.message,
                  }
                );

                await checkIfUserEmailTemplateHasToUpdate();
              }}
            >
              Send Email
            </NdAsyncButton>
          </Stack>
        </Stack>
      </Box>

      {/* Update Email Template Dialog */}
      <UpdateEmailTemplateDialog
        open={openUpdateEmailTemplate}
        setOpen={setOpenUpdateEmailTemplate}
        yesAction={async () => {
          await updateUserEmailTemplate();
        }}
        noAction={async () => {
          await sendEmail();
        }}
      />
    </>
  );
};

export default SingleMailing;
